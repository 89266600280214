import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Spacer } from '../spacer';
import { Heading, Text } from './text';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  height: 100vh;
  min-width: 100vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.overlay};
  z-index: 11;
`;

const ModalCard = styled.div`
  position: relative;
  padding: 1.5rem;
  width: 80%;
  max-width: 30rem;
  max-height: 80%;
  overflow: scroll;
  background-color: ${(props) => props.theme.noContrast};
  @media (min-width: ${(props) => props.theme.medium}) {
    width: 100%;
  }
`;

export const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 2;
`;

export default function Modal({ children, heading, text, close = () => null }) {
  /**
   * Close the modal when the escape key is pressed
   */
  const closeOnEsc = useCallback(
    (e) => {
      if (e.key === 'Escape') close();
    },
    [close]
  );

  /**
   * Bind keyup event to `closeOnEsc` function
   */
  useEffect(() => {
    document.addEventListener('keyup', closeOnEsc);
    return () => document.removeEventListener('keyup', closeOnEsc);
  }, [closeOnEsc]);

  return (
    <ModalBackground>
      <ModalCard>
        <Heading size="h2">{heading}</Heading>
        {text && <Text>{text}</Text>}
        <Spacer size={1} />
        {children}
      </ModalCard>
    </ModalBackground>
  );
}
