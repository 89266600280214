import { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Spacer } from './spacer';
import { Button, ButtonSecondary } from './styled/button';
import { Heading, Text } from './styled/text';

const ConfirmationBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  height: 100vh;
  min-width: 100vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.overlay};
  z-index: 10;
`;

const ConfirmationCard = styled.div`
  position: relative;
  padding: 1.5rem;
  width: 80%;
  max-width: 20rem;
  background-color: ${(props) => props.theme.noContrast};
  @media (min-width: ${(props) => props.theme.medium}) {
    width: 100%;
  }
`;

const ConfirmationActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ActionsRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 2;
`;

export default function Confirmation({
  onConfirm,
  onCancel,
  heading,
  text,
  close = () => null,
  loading = false,
}) {
  /**
   * Close the modal when the escape key is pressed
   */
  const closeOnEsc = useCallback(
    (e) => {
      if (e.key === 'Escape') close();
    },
    [close]
  );

  /**
   * Bind keyup event to `closeOnEsc` function
   */
  useEffect(() => {
    document.addEventListener('keyup', closeOnEsc);
    return () => document.removeEventListener('keyup', closeOnEsc);
  }, [closeOnEsc]);

  return (
    <ConfirmationBackground>
      <ConfirmationCard>
        <Heading size="h2">{heading}</Heading>
        {text && <Text>{text}</Text>}
        <Spacer size={2} />
        <ConfirmationActions>
          <ActionsRight>
            <ButtonSecondary onClick={onCancel}>Annuleren</ButtonSecondary>
            <Spacer size={1} />
            <Button onClick={onConfirm} loading={loading}>
              Bevestigen
            </Button>
          </ActionsRight>
        </ConfirmationActions>
      </ConfirmationCard>
    </ConfirmationBackground>
  );
}
