import styled from 'styled-components';

export const Text = styled.p`
  margin: 0;
  font-size: 0.75rem;
  font-weight: ${(props) => (props.strong ? 700 : 400)};
  line-height: 1.25rem;
  text-align: ${(props) =>
    (props.align === 'center' && 'center') ||
    (props.align === 'right' && 'right') ||
    (props.align === 'left' && 'left')};
  color: ${(props) =>
    props.secondary ? props.theme.secondaryText : props.theme.primaryText};
`;

export const Label = styled.label`
  margin: 0;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: ${(props) => props.theme.secondaryText};
`;

export const Annotation = styled.p`
  margin: 0;
  font-size: 0.625rem;
  font-weight: ${(props) => (props.strong ? 600 : 400)};
  line-height: 1.25rem;
  color: ${(props) => props.theme.secondaryText};
`;

const H1 = styled.h1`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  color: ${(props) => props.theme.primaryText};
`;

const H2 = styled.h2`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: ${(props) => props.theme.primary};
`;

const H3 = styled.h3`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 110%;
  color: ${(props) => props.theme.primaryText};
`;

export function Heading({ size, ...rest }) {
  switch (size) {
    case 'h3':
      return <H3 {...rest} />;
    case 'h2':
      return <H2 {...rest} />;
    case 'h1':
    default:
      return <H1 {...rest} />;
  }
}
