import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'styled-components';
import { Provider as URQLProvider, createClient } from 'urql';

import CompleteUserProfile from '../components/modals/complete-user-profile';
import '../styles/global.css';
import { theme } from '../theme';

const client = createClient({
  url: '/api/graphql',
});

function MyApp({ Component, pageProps }) {
  return (
    <ThemeProvider theme={theme}>
      <URQLProvider value={client}>
        <Head>
          <title>CorporatieNL</title>
        </Head>
        <CompleteUserProfile />
        <Component {...pageProps} />
        <Toaster position="bottom-right" />
      </URQLProvider>
    </ThemeProvider>
  );
}

export default MyApp;
