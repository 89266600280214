import { Spinner } from '@chakra-ui/spinner';
import styled from 'styled-components';

export { Spinner };

export default Spinner;

const LoaderIcon = styled.object`
  align-self: center;
  justify-self: center;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '2rem')};
  margin: 0 auto;
`;

export function PageLoader() {
  return (
    <LoaderIcon type="image/svg+xml" data="/loading-indicator.svg">
      svg loading animation
    </LoaderIcon>
  );
}
