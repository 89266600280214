import Router from 'next/router';
import { useEffect } from 'react';
import useWindowFocus from 'use-window-focus';
import create from 'zustand';

import { useMeQuery } from '../lib/graphql';

const useStore = create((set) => ({
  user: null,
  error: null,
  setUser: (user) => set(() => ({ user: user })),
  setError: (error) => set(() => ({ error: error })),
}));

export default function useUser({
  redirectTo = '',
  redirectIfFound = false,
} = {}) {
  const { user, error, setUser, setError } = useStore();
  const [{ data, fetching, error: queryError }, fetchCurrentUser] =
    useMeQuery();
  const isWindowFocused = useWindowFocus();

  useEffect(() => {
    if (!fetching && data?.me) {
      setUser(data.me);
    }
    setError(queryError);

    if (isWindowFocused && !fetching) {
      fetchCurrentUser();
    }
  }, [data, fetching, queryError, setError, setUser, isWindowFocused]);

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || (!user && !error)) return;

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user)
    ) {
      Router.push(redirectTo);
    }
  }, [user, redirectIfFound, redirectTo, error]);

  return { user, reload: fetchCurrentUser };
}
