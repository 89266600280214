import { Formik } from 'formik';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import useUser from '../../hooks/use-user';
import { useUpdateUserMutation } from '../../lib/graphql';
import { Spacer } from '../spacer';
import { Button } from '../styled/button';
import { Form } from '../styled/form';
import { InputField } from '../styled/input';
import { Layout } from '../styled/layout';
import Modal, { ActionsRight, ModalActions } from '../styled/modal';

const schema = Yup.object({
  name: Yup.string().required('Verplicht'),
  surname: Yup.string().required('Verplicht'),
  job: Yup.string().required('Verplicht'),
});

export default function CompleteUserProfile() {
  const { user, reload } = useUser();
  const [, updateUser] = useUpdateUserMutation();

  const firstInput = useRef(null);

  async function saveUser(input, bag) {
    try {
      const { error: updateUserError } = await updateUser({
        id: user.id,
        input,
      });
      if (updateUserError) throw updateUserError;
      reload();
    } catch (err) {
      toast.error('Er ging iets fout: ', err.message);
    } finally {
      bag.setSubmitting(false);
    }
  }

  /**
   * Focus the first input when the modal opens
   */
  useEffect(() => {
    if (firstInput.current) firstInput.current.focus();
  }, [firstInput]);

  if (!user) {
    return null;
  }

  if (user.name && user.surname && user.job) {
    return null;
  }

  return (
    <Modal
      heading="Welkom bij CorporatieNL"
      text="Gefeliciteerd met je lidmaatschap bij CorporatieNL. We willen graag meer over je weten zodat we je beter van dienst kunnen zijn. Welke gebruikersgegevens mogen we noteren?"
    >
      <Formik
        validationSchema={schema}
        initialValues={{
          name: user.name || '',
          surname: user.surname || '',
          job: user.job || '',
          organisationId: user.organisation.id,
          email: user.email,
        }}
        onSubmit={saveUser}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Layout columns="1fr 1.5rem 1fr">
              <InputField
                ref={firstInput}
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.errors.name}
                placeholder="Voornaam"
                label="Voornaam"
              />
              <Spacer size={2} />
              <InputField
                id="surname"
                name="surname"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.surname}
                error={formik.errors.surname}
                placeholder="Achternaam"
                label="Achternaam"
              />
            </Layout>
            <Spacer size={1} />
            <InputField
              id="job"
              name="job"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.job}
              error={formik.errors.job}
              placeholder="Functie"
              label="Functie"
            />
            <Spacer size={1.5} />
            <ModalActions>
              <ActionsRight>
                <Button
                  type="submit"
                  disabled={!formik.isValid}
                  loading={formik.isSubmitting}
                >
                  Opslaan en aan de slag
                </Button>
              </ActionsRight>
            </ModalActions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
