import styled from 'styled-components';

function getHeight({ axis, size = 1 }) {
  return axis === 'horizontal' ? 1 : size;
}

function getWidth({ axis, size = 1 }) {
  return axis === 'vertical' ? 1 : size;
}

export const Spacer = styled.span`
  display: block;
  width: ${getWidth}rem;
  min-width: ${getWidth}rem;
  height: ${getHeight}rem;
  min-height: ${getHeight}rem;
`;

export default Spacer;
