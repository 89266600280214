import styled, { keyframes } from 'styled-components';

import { Spacer } from '../spacer';
import { Annotation } from '../styled/text';

const fadeInLeftDown = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const MenuWrapper = styled.div`
  position: relative;
`;

const MenuItems = styled.ul`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  min-width: max-content;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  background-color: ${(props) => props.theme.noContrast};
  box-shadow: ${(props) => props.theme.shadowCard};
  list-style-type: none;
  z-index: 10;
  transform: scale(0);
  transform-origin: top right;
  animation: ${fadeInLeftDown};
  animation-duration: 0.125s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
`;

const MenuItemRow = styled.li`
  list-style: none;
  min-width: fit-content;
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.noContrastHover};
  }
`;

const MenuIcon = styled.span`
  height: 1.25rem;
  width: 1.25rem;
  font-size: 1.25rem;
  color: ${(props) => props.theme.secondaryText};
`;

export function Menu({ trigger, children, showMenu }) {
  return (
    <MenuWrapper>
      {trigger}
      {showMenu && <MenuItems>{children}</MenuItems>}
    </MenuWrapper>
  );
}

export function MenuItem({ icon, text, onClick }) {
  return (
    <MenuItemRow onClick={onClick}>
      {icon && (
        <>
          <MenuIcon className="material-icons">{icon}</MenuIcon>
          <Spacer size={0.5} />
        </>
      )}
      <Annotation strong>{text}</Annotation>
    </MenuItemRow>
  );
}
