import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Confirmation from '../confirmation';
import Spinner from '../spinner';
import { Icon } from './icon';
import { Annotation } from './text';

const StyledButton = styled.button`
  ${(props) =>
    props.notifications &&
    css`
      position: relative;
    `}
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'initial')};
  justify-self: ${(props) =>
    props.justifySelf ? props.justifySelf : 'initial'};
  margin: 0;
  padding: 0.5rem 0.75rem;
  border: 0;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: 0.03em;
  color: ${(props) => props.theme.noContrast};
  background-color: ${(props) => props.theme.primary};
  cursor: pointer;
  transition: 0.225s all;
  &:hover {
    background-color: ${(props) => props.theme.primaryHover};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const StyledSpinner = styled(Spinner)`
  width: 10px;
  height: 10px;
  margin: 5px;
`;

const Notification = styled.div`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  padding: 0.125rem;
  border-radius: 10px;
  line-height: 12px;
  color: ${(props) => props.theme.noContrast};
  background-color: ${(props) => props.theme.danger};
`;

const NotificationText = styled(Annotation)`
  min-width: 12px;
  line-height: initial;
  color: ${(props) => props.theme.noContrast};
`;

export const Button = React.forwardRef(function Button(props, ref) {
  return (
    <StyledButton
      {...props}
      ref={ref}
      disabled={props.loading ? true : props.disabled}
      notifications={props.notifications}
    >
      {/* TODO: StyledSpinner should fall over existing content not replace it */}
      {props.loading ? <StyledSpinner /> : props.children}
      {props.notifications && (
        <Notification>
          <NotificationText>{props.notifications}</NotificationText>
        </Notification>
      )}
    </StyledButton>
  );
});

Button.propTypes = {
  loading: PropTypes.bool,
};

export const ButtonSecondary = styled(Button)`
  color: ${(props) => props.theme.primaryText};
  background-color: ${(props) => props.theme.transparent};
  &:hover {
    background-color: ${(props) => props.theme.transparentHover};
  }
`;

export const IconButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'initial')};
  justify-self: ${(props) =>
    props.justifySelf ? props.justifySelf : 'initial'};
  margin: 0;
  padding: ${(props) => (props.tiny ? '.25rem' : '.5rem')};
  border: 0;
  border-radius: 0.25rem;
  color: ${(props) => props.theme.noContrast};
  background-color: ${(props) => props.theme.primary};
  cursor: pointer;
  transition: 0.225s all;
  &:hover {
    background-color: ${(props) => props.theme.primaryHover};
  }
`;

export const IconButtonSecondary = styled(IconButton)`
  position: relative;
  color: ${(props) => props.theme.secondaryText};
  background-color: ${(props) =>
    props.active ? props.theme.transparentHover : props.theme.transparent};
  &:hover {
    color: ${(props) => props.theme.primaryText};
    background-color: ${(props) => props.theme.transparentHover};
  }
`;

export function IconButtonWithConfirm(props) {
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <>
      <IconButtonSecondary
        {...props}
        type="button"
        onClick={(e) => {
          setShowConfirm(true);
        }}
      >
        <Icon tiny={props.tiny} className="material-icons">
          {props.icon ? props.icon : 'delete'}
        </Icon>
      </IconButtonSecondary>
      {showConfirm ? (
        <Confirmation
          heading={props.heading ? props.heading : 'Verwijderen'}
          text={
            props.message
              ? props.message
              : 'Weet je zeker dat je deze actie wilt uitvoeren?'
          }
          onCancel={() => setShowConfirm(false)}
          onConfirm={() => {
            setShowConfirm(false);
            props.onConfirm();
          }}
        />
      ) : null}
    </>
  );
}
