import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Menu, MenuItem } from '../components/styled/menu';
import { IconButtonSecondary } from './styled/button';

const WysiwygWrapper = styled.div`
  position: relative;
`;

const WysiwygMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.125rem 0.125rem 0.0625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${(props) => props.theme.stroke};
  z-index: 1;
`;

const WysiwygMenuIcon = styled.span`
  font-size: 1.25rem;
`;

const WysiwygEditorContent = styled(EditorContent)`
  div {
    min-height: 6rem;
    margin: 0;
    padding: 3rem 1rem 0.75rem;
    border: solid 1px
      ${(props) => (props.error ? props.theme.danger : props.theme.stroke)};
    border-radius: 0.25rem;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.25rem;
    background-color: ${(props) => props.theme.noContrast};
  }
`;

function MenuBar({ editor }) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  function ToggleFormat(level) {
    if (level > 0) {
      editor.chain().focus().toggleHeading({ level: level }).run();
    } else {
      editor.chain().focus().setParagraph().run();
    }
    setShowMenu(false);
  }

  if (!editor) {
    return null;
  }

  return (
    <WysiwygMenu>
      <IconButtonSecondary
        active={editor.isActive('bold')}
        type="button"
        aria-label="Gebruik of verwijder bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <WysiwygMenuIcon className="material-icons">
          format_bold
        </WysiwygMenuIcon>
      </IconButtonSecondary>
      <IconButtonSecondary
        type="button"
        active={editor.isActive('italic')}
        aria-label="Gebruik of verwijder italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <WysiwygMenuIcon className="material-icons">
          format_italic
        </WysiwygMenuIcon>
      </IconButtonSecondary>
      <IconButtonSecondary
        type="button"
        active={editor.isActive('strike')}
        aria-label="Gebruik of verwijder strikethrough"
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <WysiwygMenuIcon className="material-icons">
          format_strikethrough
        </WysiwygMenuIcon>
      </IconButtonSecondary>
      <Menu
        showMenu={showMenu}
        trigger={
          <IconButtonSecondary
            type="button"
            aria-label="Kies een tekstformaat"
            onClick={() => setShowMenu(!showMenu)}
          >
            <WysiwygMenuIcon className="material-icons">
              format_size
            </WysiwygMenuIcon>
          </IconButtonSecondary>
        }
      >
        <div ref={menuRef}>
          <MenuItem onClick={() => ToggleFormat(2)} text="Heading 1" />
          <MenuItem onClick={() => ToggleFormat(3)} text="Heading 2" />
          <MenuItem onClick={() => ToggleFormat(0)} text="Paragraaf" />
        </div>
      </Menu>
      <IconButtonSecondary
        type="button"
        aria-label="Gebruik of verwijder een bulletlijst"
        onClick={() => editor.chain().toggleBulletList().run()}
      >
        <WysiwygMenuIcon className="material-icons">
          format_list_bulleted
        </WysiwygMenuIcon>
      </IconButtonSecondary>
      <IconButtonSecondary
        type="button"
        aria-label="Gebruik of verwijder een numerieke lijst"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <WysiwygMenuIcon className="material-icons">
          format_list_numbered
        </WysiwygMenuIcon>
      </IconButtonSecondary>
    </WysiwygMenu>
  );
}

export default function WYSIWYG({ value, ...props }) {
  const editor = useEditor({
    extensions: [StarterKit],
    content: value,
    onUpdate({ editor }) {
      props.onChange(editor.getHTML());
    },
  });

  return (
    <WysiwygWrapper>
      <MenuBar editor={editor} />
      <WysiwygEditorContent editor={editor} {...props} />
    </WysiwygWrapper>
  );
}
