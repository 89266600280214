import styled from 'styled-components';

export const Main = styled.main`
  min-width: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.background};
`;

export const Auth = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 22rem;
  max-width: 22rem;
  padding: 2rem;
  background: ${(props) => props.theme.noContrast};
`;

export const SidenavLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  @media (min-width: ${(props) => props.theme.medium}) {
    grid-template-columns: 16rem 1fr;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ContentInner = styled.div`
  width: 100%;
  max-width: 75rem;
  padding: 0.5rem 1rem;
  @media (min-width: ${(props) => props.theme.medium}) {
    padding: 1rem 1.5rem;
  }
`;

export function Content({ children }) {
  return (
    <ContentWrapper>
      <ContentInner>{children}</ContentInner>
    </ContentWrapper>
  );
}

export const ContentHeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-start;
  height: 6rem;
  border-bottom: 1px solid ${(props) => props.theme.stroke};
  border-left: 1px solid ${(props) => props.theme.stroke};
  background-color: ${(props) => props.theme.noContrast};
  z-index: 10;
`;

export const ContentHeaderInner = styled.div`
  width: 100%;
  max-width: 75rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${(props) => props.theme.medium}) {
    padding: 1rem 1.5rem;
  }
`;

export function ContentHeader({ children }) {
  return (
    <ContentHeaderWrapper>
      <ContentHeaderInner>{children}</ContentHeaderInner>
    </ContentHeaderWrapper>
  );
}

export const Layout = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columnsMobile ? props.columnsMobile : '1fr'};
  grid-gap: ${(props) => (props.gap ? props.gap : '0')};
  place-content: start;
  align-items: ${(props) => (props.align ? props.align : 'initial')};
  @media (min-width: ${(props) => props.theme.medium}) {
    grid-template-columns: ${(props) =>
      props.columns ? props.columns : '1fr'};
  }
`;

export const HideM = styled.span`
  display: none;
  @media (min-width: ${(props) => props.theme.medium}) {
    display: initial;
  }
`;
