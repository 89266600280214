const lightThemeColors = {
  //colors
  background: '#F7F7F7',
  stroke: '#E5E5E5',
  noContrast: '#FFFFFF',
  noContrastHover: '#FEEEE9',
  contrast: '#202020',
  primary: '#F05323',
  primaryHover: '#D63A0B',
  danger: '#FF0000',
  success: '#33BF1C',

  //text colors
  primaryText: '#202020',
  secondaryText: '#727272',

  //others
  transparent: 'transparent',
  transparentHover: 'rgba(0,0,0,0.08)',
  overlay: 'rgba(0,0,0,0.45)',
};

const effects = {
  shadowMenu:
    '0px 16px 32px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08)',
  shadowCard:
    '0px 16px 32px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.08)',
};

const breakPoints = {
  medium: '48rem',
};

export const theme = {
  ...lightThemeColors,
  ...effects,
  ...breakPoints,
};
