import styled from 'styled-components';

const IconElement = styled.span`
  font-size: ${(props) => (props.tiny ? '20px' : '24px')};
`;

export function Icon({ children, tiny }) {
  return (
    <IconElement tiny={tiny} className="material-icons">
      {children}
    </IconElement>
  );
}
