import Image from 'next/image';
import PropTypes from 'prop-types';
import { forwardRef, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Spacer } from '../spacer';
import WYSIWYG from '../wysiwyg';
import { Button } from './button';
import { Annotation, Label, Text } from './text';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  align-self: stretch;
  position: relative;
`;

const InputIcon = styled.span`
  position: absolute;
  bottom: 0.5rem;
  left: 0.75rem;
  color: ${(props) => props.theme.secondaryText};
`;

const Input = styled.input`
  margin: 0;
  padding: 0.75rem 1rem;
  padding: ${(props) => props.hasIcon && '.75rem 1rem .75rem 2.5rem'};
  padding: ${(props) => props.small && '.25rem .5rem'};
  border: solid 1px
    ${(props) => (props.error ? props.theme.danger : props.theme.stroke)};
  border-radius: 0.25rem;
  font-size: 0.75rem;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const InputHelpers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorText = styled(Annotation)`
  color: ${(props) => props.theme.danger};
`;

const Select = styled.select`
  margin: 0;
  padding: ${(props) => (props.small ? '.25rem .5rem' : '0.75rem 1rem')};
  border: solid 1px
    ${(props) => (props.error ? props.theme.danger : props.theme.stroke)};
  border-radius: 0.25rem;
  font-size: 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/icons/dropdownArrow.svg');
  background-repeat: no-repeat;
  background-position: right center;

  &::disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const Checkbox = styled.input`
  &[type='checkbox'] {
    height: 1rem;
    width: 1rem;
    margin: 0 0.5rem 0 0;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UploadAreaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 12rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.noContrast};
  border: solid 1px
    ${(props) => (props.error ? props.theme.danger : props.theme.stroke)};
`;

export const UploadArea = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  margin: 0;
  padding: ${(props) =>
    props.hasIcon ? '.75rem 1rem .75rem 2.5rem' : '0.75rem 1rem'};
  border: solid 1px
    ${(props) => (props.error ? props.theme.danger : props.theme.stroke)};
  border-radius: 0.25rem;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const InputField = forwardRef(function InputField(props, ref) {
  return (
    <InputWrapper>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Spacer size={0.0625} />
      {props.icon && (
        <InputIcon className="material-icons">{props.icon}</InputIcon>
      )}
      <Input hasIcon={props.icon} {...props} ref={ref} />
      <InputHelpers>
        {props.helper && <Annotation>{props.helper}</Annotation>}
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </InputHelpers>
    </InputWrapper>
  );
});

export const SelectField = forwardRef(function SelectField(
  { id, label, options, helper, error, ...rest },
  ref
) {
  return (
    <InputWrapper>
      <Label htmlFor={id}>{label}</Label>
      <Spacer size={0.0625} />
      <Select {...rest} ref={ref}>
        {options}
      </Select>
      <InputHelpers>
        {helper && <Annotation>{helper}</Annotation>}
        {error && <ErrorText>{error}</ErrorText>}
      </InputHelpers>
    </InputWrapper>
  );
});

export const InputCheckbox = forwardRef(function InputCheckbox(props, ref) {
  return (
    <>
      <CheckboxWrapper>
        <Checkbox type="checkbox" {...props} ref={ref} />
        <Label htmlFor={props.id}>{props.label}</Label>
      </CheckboxWrapper>
      <InputHelpers>
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </InputHelpers>
    </>
  );
});

export function InputUploadField({
  id,
  children,
  onChange,
  files = [],
  label,
  multiple = true,
  ...props
}) {
  const inputFileRef = useRef(null);

  function addFile(e) {
    const selectedFiles = e.target.files;
    const newFiles = [...files, ...selectedFiles];
    if (onChange) {
      onChange(newFiles);
    }
    inputFileRef.current.value = '';
  }

  function removeFile(file) {
    const index = files.indexOf(file);
    if (index !== -1) {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      if (onChange) {
        onChange(newFiles);
      }
    }
  }

  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <Spacer size={0.0625} />
      {children ? children({ files, removeFile }) : null}
      {files && files.length ? <Spacer size={1} /> : null}
      <UploadAreaWrapper error={props.error}>
        <UploadArea
          {...props}
          multiple={multiple}
          id={id}
          type="file"
          ref={inputFileRef}
          onChange={addFile}
        />
        <Spacer size={1} />
        <Text strong>Sleep hier je bestand(en) naar toe.</Text>
        <Spacer size={1.5} />
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (inputFileRef.current) {
              inputFileRef.current.click();
            }
          }}
        >
          Kies een bestand
        </Button>
      </UploadAreaWrapper>
      <InputHelpers>
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </InputHelpers>
    </>
  );
}

InputUploadField.propTypes = {
  children: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string,
};

const InputImagePreviewWrapper = styled.div`
  position: relative;
  display: block;
`;

const ImagePreview = styled(Image)`
  object-fit: cover;
`;

const InputImagePreviewActions = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
`;

export function InputImagePreview({ src, actions }) {
  return (
    <InputImagePreviewWrapper>
      <Label>Afbeelding</Label>
      <Spacer size={0.125} />
      <ImagePreview src={src} layout="responsive" width="100" height="40" />
      <InputImagePreviewActions>{actions}</InputImagePreviewActions>
    </InputImagePreviewWrapper>
  );
}

export function TextAreaField(props) {
  return (
    <InputWrapper>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Spacer size={0.0625} />
      {props.icon && (
        <InputIcon className="material-icons">{props.icon}</InputIcon>
      )}
      {props.wysiwyg ? (
        <WYSIWYG {...props} onChange={(html) => props.onChange(html)} />
      ) : (
        <TextArea hasIcon={props.icon} {...props} />
      )}
      <InputHelpers>
        {props.helper && <Annotation>{props.helper}</Annotation>}
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </InputHelpers>
    </InputWrapper>
  );
}

export const Recommendations = styled.div`
  position: absolute;
  ${(props) => (props.top ? 'top: 1rem' : 'bottom: -.25rem')};
  width: calc(100% - 2px);
  background-color: ${(props) => props.theme.noContrast};
  border: 1px solid ${(props) => props.theme.stroke};
  border-radius: 0.25rem;
  ${(props) =>
    props.top
      ? 'transform: translateY(-100%);'
      : 'transform: translateY(100%);'};
`;

export const Recommendation = styled(Text)`
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.225s all;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) =>
    !props.disabled
      ? css`
          &:hover {
            background-color: ${(props) => props.theme.noContrastHover};
          }
        `
      : ''}
`;

export const AutoComplete = forwardRef(function InputField(props, ref) {
  return (
    <InputWrapper>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Spacer size={0.0625} />
      {props.icon && (
        <InputIcon className="material-icons">{props.icon}</InputIcon>
      )}
      <Input hasIcon={props.icon} {...props} ref={ref} />
      <InputHelpers>
        {props.helper && <Annotation>{props.helper}</Annotation>}
        {props.error && <ErrorText>{props.error}</ErrorText>}
      </InputHelpers>
      {props.recommendations}
    </InputWrapper>
  );
});
